(function ($) {
	// setTimeout(() => {
	// 	$('.faq-item').eq(0).addClass('active');
	// 	$('.faq-item').eq(0).find('.faq-item__a').show();
	// }, 60);

	// Internal Link
	$('.js-internal-link').on('click', function (e) {
		e.preventDefault();
		const target = $(this).attr('href');
		const targetPos = $(target).offset().top;
		$('html, body').animate(
			{
				scrollTop: targetPos
			},
			1800,
			'easeInOutQuart'
		);
	});

	$('.faq-item__q').on('click', function () {
		const activeItem = $(this).closest('.faq-item');
		const activeAnswer = activeItem.find('.faq-item__a');

		if (activeItem.hasClass('active')) {
			activeItem.removeClass('active');
			activeAnswer.slideUp(400, 'easeInOutQuart');
		} else {
			activeItem.addClass('active');
			$('.faq-item').not(activeItem).removeClass('active');

			activeAnswer.slideDown(400, 'easeInOutQuart');
			$('.faq-item__a').not(activeAnswer).slideUp(400, 'easeInOutQuart');
		}
	});
})(jQuery);
